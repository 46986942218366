@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
  a {
    @apply text-indigo-300;
  }
  a:hover {
    @apply text-white;
  }
}
